import { DescriptionType } from '@/app/types/companyProfileTypes'
import { CountryType, Language, ListType } from '@/app/types/constantDataType'
import { Option } from '@/components/multiselectInput'

export const fieldsToCompare = [
  { name: 'logo', newName: 'newCompanyLogo' },
  { name: 'name', newName: 'newCompanyName' },
  { name: 'company_url', newName: 'newWebsite' },
  { name: 'country_id', newName: 'newCountry' },
  { name: 'phone_number', newName: 'newPhoneNo' },
  { name: 'street_address', newName: 'newStreetAddress' },
  { name: 'year_of_start', newName: 'newYearOfStart' },
  { name: 'turnover', newName: 'newTurnOver' },
  { name: 'registration_number', newName: 'newRegistrationNumber' },
  { name: 'number_of_permanent_employees', newName: 'newTotalEmployee' },
  { name: 'language', newName: 'newLanguage' },
  { name: 'description', newName: 'newDescription' },
  { name: 'language_id', newName: 'newLanguage_id' },
  { name: 'province_id', newName: 'newProvince' },
  { name: 'region', newName: 'newRegion' },
  { name: 'city', newName: 'newCity' },
  { name: 'phone_code_id', newName: 'newPhCode' },
  { name: 'activity', newName: 'newActivities' },
  { name: 'certification', newName: 'newCertification' },
  { name: 'commercial_zone', newName: 'newcommercial_zone' },
]

export const groupData = async (
  companyProfile: any,
  languages: Language[],
  activityList: ListType[],
  certificationsList: ListType[],
  countriesList: CountryType[],
  descriptionList: DescriptionType[]
) => {
  const companydata = new FormData()
  let addressAppended = false
  try {
    await Promise.all(
      fieldsToCompare.map(async (field) => {
        const oldValue = companyProfile[field.name]
        const newValue = companyProfile[field.newName]
        if (oldValue === newValue) return
        switch (field.name) {
          case 'name':
          case 'phone_number':
          case 'company_url':
          case 'logo':
          case 'year_of_start':
          case 'turnover':
          case 'registration_number':
          case 'number_of_permanent_employees':
          case 'phone_code_id':
            if (newValue) companydata.append(`company[${field.name}]`, newValue)
            break

          case 'country_id':
          case 'province_id':
          case 'region':
          case 'city':
          case 'street_address':
            if (!addressAppended) {
              if (companyProfile?.newStreetAddress) {
                companydata.append(
                  `company[company_address_attributes][street_address]`,
                  companyProfile.newStreetAddress
                )
              }
              companydata.append(
                `company[company_address_attributes][country_id]`,
                companyProfile.newCountry
              )
              if (companyProfile?.newProvince?.id > 0) {
                companydata.append(
                  `company[company_address_attributes][province_id]`,
                  companyProfile?.newProvince?.id
                )
              }
              if (
                companyProfile?.newCity?.id &&
                companyProfile?.newCity?.id !== String(0)
              ) {
                companydata.append(
                  `company[company_address_attributes][city_id]`,
                  companyProfile?.newCity?.id
                )
              }
              else{
                companydata.append(
                  `company[company_address_attributes][custom_city]`,
                  companyProfile?.newCustomCity
                )
              }
              // if (
              //   (companyProfile?.city?.id !== companyProfile?.newCity?.id )||
              //   companyProfile?.newCity?.id === String(0)
              // ) {
              //   companydata.append(
              //     `company[company_address_attributes][custom_city]`,
              //     companyProfile?.newCustomCity
              //   )
              // }
              if (companyProfile?.newRegion?.id) {
                companydata.append(
                  `company[company_address_attributes][region_id]`,
                  companyProfile?.newRegion?.id
                )
              }
            }
            addressAppended = true
            break
          case 'language':
          case 'activity':
          case 'certification':
          case 'commercial_zone': {
            const oldValueString = oldValue?.join(',')
            const newValueString = newValue.join(',')
            const canUpdate =
              newValueString === ''
                ? field.name === 'activity'
                  ? false
                  : true
                : true
            if (oldValueString !== newValueString && canUpdate) {
              if (newValueString === '') {
                companydata.append(`company[${field.name}_ids][]`, '')
              } else {
                const list = {
                  language: languages,
                  activity: activityList,
                  certification: certificationsList,
                  commercial_zone: countriesList,
                }

                const filteredData = list[field.name].filter((item: any) =>
                  newValueString.includes(item.name)
                )

                filteredData.forEach((id: any) => {
                  newValue.forEach((item: any) => {
                    if (id.name === item) {
                      companydata.append(`company[${field.name}_ids][]`, id.id)
                    }
                  })
                })
              }
            }
            break
          }
        }
      })
    )
    if (descriptionList.length > 0) {
      descriptionList.forEach((item) => {
        companydata.append(
          `company[company_descriptions_attributes][${item?.language?.id}][content]`,
          item?.content
        )
      })
    }
    return companydata
  } catch (error) {
    console.error('Error while grouping data:', error)
    throw error
  }
}

export function extractNames(data: any) {
  return data?.map((item: any) => item.name)
}

export function extractIds(data: any) {
  return data?.map((item: any) => item.id)
}

export function convertToFormat(input: Option[] | string) {
  if (Array.isArray(input) && input.length > 0) {
    return input.map((item) => ({
      name: item?.name?.toString() || item?.toString(),
      value: item?.value?.toString() || item?.toString(),
    }))
  } else if (typeof input === 'string') {
    return [
      {
        name: input?.toString(),
        value: input?.toString(),
      },
    ]
  } else {
    return []
  }
}

export const getCompanyProfileData = async (id: string | string[]) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/companies/${id}/profile`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const reponseData = await response.json()
    return reponseData
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const askQuote = async (
  companyId: string,
  productId: number,
  message: string
) => {
  const response = await fetch(
    `${process.env.API_URL}/companies/${companyId}/ask_quote`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        product_id: productId,
        message: message,
      }),
    }
  )
  const data = await response.json()
  return data
}

export const productCountryCard = async (id: string | string[]) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/companies/${id}/logistics`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const productStatsData = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/companies/${id}/stats`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const companyProductCardData = async (
  id: string,
  lng: string | string[]
) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/companies/${id}/company_products?lang=${lng}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

// List all the companies

export const companyOffersData = async (id: string, page: number) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/companies/${id}/offers?page=${page}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const companyNewsData = async (
  id: string | string[],
  page: number,
  lng: string | string[],
  order?: string
) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/companies/${id}/news?page=${page}&order=${order ?? 'desc'}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const validateCompanyProfile = (companyProfile: any) => {
  if (
    companyProfile.newCompanyName !== '' &&
    companyProfile.newStreetAddress !== '' &&
    companyProfile.newPhoneNo !== '' && companyProfile.newActivities.length > 0
  ) {
    return true
  } else {
    return false
  }
}
